<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称/编号" label-width="80px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item label="年级" label-width="50px" prop="ninji">
				  <el-select v-model="queryForm.nianji" placeholder="请选择年级" style="width: 100%;">
				    <el-option :value="item.id" :label="item.name" v-for="(item,index) in nianji" :key="item.id"/>
				  </el-select>
				</el-form-item>
				<el-form-item label="学科" label-width="50px" prop="xueke">
				  <el-select v-model="queryForm.xueke" placeholder="请选择学科" style="width: 100%;">
				    <el-option :value="item.id" :label="item.name" v-for="(item,index) in xueke" :key="item.id"/>
				  </el-select>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<!-- <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">申请</el-button> -->
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="申请编号" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.code || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="姓名" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.t_name || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="年级" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.nianji || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="学科" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.xueke || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="类型" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.type_name || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="分数" min-width="110" align="center" >
				  <template slot-scope="scope">
					<span>{{ scope.row.score || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
				  <template slot-scope="scope">
						<el-tag v-if="scope.row.status === 1" size="mini" type="success">已审核</el-tag>
						<el-tag v-if="scope.row.status === 2" size="mini" type="warning">待审核</el-tag>
						<el-tag v-if="scope.row.status === 3" size="mini" type="danger">驳回</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="160">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="查看" placement="top">
					  <el-button type="primary" icon="el-icon-view" size="mini" circle @click="handleView(scope.row)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="编辑" placement="top" v-if="queryForm.role_id == 1">
					  <el-button type="warning" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="撤回" placement="top" v-if="scope.row.status == 1">
					  <el-button type="danger" icon="el-icon-refresh-left" size="mini" circle @click="handleRecall('hjry',scope.row)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
		    <el-form-item label="荣誉名称" prop="title">
		       <el-input v-model="form.title" placeholder="请输入荣誉名称" type="text" clearable />
		    </el-form-item>
		    <el-form-item label="奖励级别">
		    	<el-radio-group v-model="form.type">
		    		<el-radio :label="1" class="w-100 mb-2">国家级以上奖励(5分)</el-radio>
		    		<el-radio :label="2" class="w-100 mb-2">省级以上奖励(4分)</el-radio>
		    		<el-radio :label="3" class="w-100 mb-2">市级奖励（市或市政府盖章）(3分)</el-radio>
		    		<el-radio :label="4" class="w-100 mb-2">市教育局级奖励(含市人社局)(2分)</el-radio>
		    		<el-radio :label="5" class="w-100 mb-2">校级奖励(1分)</el-radio>
		    	</el-radio-group>
		    </el-form-item>
			<el-form-item label="考核得分" prop="score">
			   <el-input v-model="form.score" placeholder="请输入考核得分" type="number" clearable />
			</el-form-item>
		    <el-form-item label="上传材料">
		    	<div class="flex flex-column flex-wrap">
		    		<div class="flex mr-1 mb-2">
		    			<el-upload
		    			  v-for="(item,index) in files" :key="index"
		    			  class="upload-border"
		    			  action="/manage/file/upload"
		    			  :show-file-list="false"
		    			  :on-success="uploadSuccess"
		    			  :before-upload="beforeUpload">
		    			  <div class="position-relative flex align-center flex-wrap w-100 overflow-hidden" style="height: 100px;" v-if="item.id">
		    				<img :src="item.path" style="width: 100px;height: 100px;" v-if="item.ext == 'jpg' || item.ext == 'jpeg' || item.ext == 'png'">
		    			  	<span style="width: 100px;word-wrap:break-word;word-break:break-all;overflow: hidden;" v-else>{{item.name || ''}}</span>
		    			  	<i class="el-icon-delete text-white position-absolute flex-all" style="width: 25px;height: 25px;top: 0;right: 0;background-color: rgba(0,0,0,0.4);cursor: pointer;" @click.stop="delFile(index)"></i>
		    			  </div>
		    			  <i v-else class="el-icon-plus uploader-icon"></i>
		    			</el-upload>
		    			<el-upload
		    			  v-if="files.length < 5"
		    			  class="upload-border"
		    			  action="/manage/file/upload"
		    			  :show-file-list="false"
		    			  :on-success="uploadSuccess"
		    			  :before-upload="beforeUpload">
		    			  <i class="el-icon-plus uploader-icon"></i>
		    			</el-upload>
		    		</div>
		    		<small class="d-block text-secondary" style="line-height: 20px;">上传照片及相关资料</small>
		    	</div>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'user_hjmc',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
					nianji:'',
					xueke:'',
					role_id:this.VueCookies.get('role_id'),
				},
				nianji:[],
				xueke:[],
				form:{
					s_id:'',
					title:'',
					type:'',
				},
				rules:{
					title: [
					  { required: true, message: '请填写荣誉名称', trigger: ['blur','change'] }
					],
				},
				files:[],
			}
		},
		mounted() {
			this.getList()
			this.getNianji()
			this.getXueke()
		},
		methods:{
			getList() {
			  this.loading = true
			  this.axios.get(`/manage/${this.preUrl}/operate_list`, {
			    params: this.queryForm
			  }).then(res => {
			    this.total = parseInt(res.total)
			    this.list = res.data
			    this.loading = false
			  }).catch(err => {
			    this.loading = false
			  })
			},
			handleView(row){
				this.$router.push({
				  path:"/hjmc_detail",
				  query: {
					  id:row.id
				  }
				})
			},
			handleEdit(row) {
			  this.reset()
			  this.open = true
			  this.title = '编辑'
			  let data = JSON.parse(JSON.stringify(row))
			  this.form = data
			  this.files = data.files
			},
			uploadSuccess(e){
			   if(e.status){
				 this.files.push(e.data)
			   }
			},
			beforeUpload(){},
			delFile(index){
				this.files.splice(index,1)
			},
			handleSubmit(){
				this.$refs.form.validate(valid => {
				  if (valid) {
					this.form.files = this.files
				    this.axios.put('/manage/'+this.preUrl + '/reset/' + this.form.id, this.form).then(res => {
				      if (res.status) {
						this.open = false
				        this.$message.success('修改成功')
						this.getList()
				      } else {
				        this.$message.error(res.msg)
				      }
				    })
				  }
				})
			},
		},
	}
</script>

<style>
</style>